<template>
    <CardComponent
        :title="title"
        :icon="icon"
        :tip="tip"
        :link="'/oa/estimate?type=9'"
        :filter="true"
        @handleFilter="handleFilter"
    >
        <template v-slot:cardOptions>
            <gb-department
                ref="oaGroup"
                v-model="selectGroupId"
                class="oaGroup"
                placeholder="全部部门"
                api-name="getAllDepts"
                :disabled-ids="ownedDeptIds"
                :is-cascader="true"
                :show-all-levels="false"
                value-filed="id"
                :expand-trigger="'click'"
                label-filed="groupName"
                @change="handleDeptChange"
                @clear="clear"
            >
            </gb-department>
        </template>
        <section>
            <div class="main-left">
                <div id="left-pie">
                </div>
            </div>
            <div class="main-right">
                <div class="grid-data">
                    <!-- 定位 -->
                    <div class="position-top top-text">绩效结果</div>
                    <div class="position-top high-rank">差 20%</div>
                    <div class="position-top medium-rank">中 70%</div>
                    <div class="position-top low-rank">优 10%</div>
                    <div class="position-top custom-arrow-top"></div>
                    <div class="position-bottom bottom-text">发展潜力</div>
                    <div class="position-bottom low-level">低</div>
                    <div class="position-bottom medium-level">中</div>
                    <div class="position-bottom high-level">高</div>
                    <div class="position-bottom custom-arrow-bottom"></div>
                    <!-- 定位 -->
                    <div class="grid-data-item grid-data-item-low" @click="showDialog(6)">
                        熟练员工
                        {{
                            account && account.sixScoreList && account.sixScoreList.length
                        }}人
                    </div>
                    <div class="grid-data-item" @click="showDialog(8)">
                        绩效之星
                        {{
                            account &&
                                account.eightScoreList &&
                                account.eightScoreList.length
                        }}人
                    </div>
                    <div class="grid-data-item" @click="showDialog(9)">
                        超级明星
                        {{
                            account && account.nineScoreList && account.nineScoreList.length
                        }}人
                    </div>
                    <div class="grid-data-item grid-data-item-medium" @click="showDialog(3)">
                        基本胜任
                        {{
                            account &&
                                account.threeScoreList &&
                                account.threeScoreList.length
                        }}人
                    </div>
                    <div class="grid-data-item grid-data-item-low" @click="showDialog(5)">
                        中坚力量
                        {{
                            account && account.fiveScoreList && account.fiveScoreList.length
                        }}人
                    </div>
                    <div class="grid-data-item" @click="showDialog(7)">
                        潜力之星
                        {{
                            account &&
                                account.sevenScoreList &&
                                account.sevenScoreList.length
                        }}人
                    </div>
                    <div
                        class="grid-data-item grid-data-item-high"
                        @click="showDialog(1)"
                    >
                        问题员工
                        {{
                            account && account.oneScoreList && account.oneScoreList.length
                        }}人
                    </div>
                    <div class="grid-data-item grid-data-item-medium" @click="showDialog(2)">
                        差距员工
                        {{
                            account && account.twoScoreList && account.twoScoreList.length
                        }}人
                    </div>
                    <div class="grid-data-item grid-data-item-medium" @click="showDialog(4)">
                        待发展者
                        {{
                            account && account.fourScoreList && account.fourScoreList.length
                        }}人
                    </div>
                </div>

                <el-dialog
                    class="add-period"
                    :visible.sync="visible"
                    title="员工列表"
                    width="40%"
                    :lock-scroll="false"
                >
                    <el-select v-model="typeValue" placeholder="请选择类型" @change="changeType">
                        <el-option
                            v-for="item in typeOptions"
                            :key="item.Value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                    <el-table :data="employeeList">
                        <el-table-column
                            prop="employeeName"
                            label="员工"
                            min-width="50"
                            maxlength="20"
                            show-word-limit
                            align="center"
                        ></el-table-column>

                        <el-table-column
                            prop="jobName"
                            label="岗位"
                            min-width="50"
                            maxlength="20"
                            show-word-limit
                            align="center"
                        ></el-table-column>

                        <el-table-column
                            prop="groupName"
                            label="所属部门"
                            min-width="50"
                            maxlength="20"
                            show-word-limit
                            align="center"
                        ></el-table-column>
                    </el-table>

                    <!-- <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleConfirm">保存</el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span> -->
                </el-dialog>
            </div>
        </section>
    </CardComponent>
</template>

<script>
import echarts from 'echarts'
import CardComponent from '../components/card'
import { moneyFilter } from '@/utils/index'
import gbDepartment from '@/components/Department'
import ehr from '@/services/ehr'

export default {
    name: '',
    components: {
        CardComponent,
        gbDepartment
    },
    props: {
        groupId: {
            type: [Number, String],
            default: null
        }
    },
    data () {
        return {
            title: '人才盘点',
            tip: '财务经营',
            icon: 'iconfont icon-huanxingtu',
            typeValue: null,
            options: [
                {
                    label: '上周',
                    value: 1
                },
                {
                    label: '本周',
                    value: 0
                }
            ],
            typeOptions: [
                {
                    label: '问题员工',
                    value: 1
                },
                {
                    label: '差距员工',
                    value: 2
                },
                {
                    label: '基本胜任',
                    value: 3
                },
                {
                    label: '待发展者',
                    value: 4
                },
                {
                    label: '中坚力量',
                    value: 5
                },
                {
                    label: '熟练员工',
                    value: 6
                },
                {
                    label: '潜力之星',
                    value: 7
                },
                {
                    label: '绩效之星',
                    value: 8
                },
                {
                    label: '超级明星',
                    value: 9
                }
            ],
            currentUser: {},
            visible: false,
            group: {},
            employeeList: [],
            bussinessDepts: [],
            selectGroupId: 0,
            data: {},
            searchData: {},
            account: {
                oneScoreList: [],
                twoScoreList: [],
                threeScoreList: []
            }
        }
    },

    computed: {
        ownedDeptIds () {
            let arr = []
            arr.push(this.selectGroupId)
            if (this.bussinessDepts.length > 0) {
                this.bussinessDepts.forEach((item) => {
                    arr.push(item.groupId)
                })
            }
            arr = [...new Set(arr)]
            // console.log(arr)
            return arr
        }
    },
    mounted () {
        this.currentUser = this.$axios.getCurrentUser()
        this.getHomeData()
    },
    created () {},
    methods: {
        moneyFilter,
        handleFilter (val) {
            //   this.getData(val)
        },
        async getHomeData () {
            this.isLoading = true
            this.searchData.groupId = this.selectGroupId
            const res = await ehr.kpiestimateForNine(this.searchData)
            this.assembleScore(res.data)
            this.init()
            this.isLoading = false
        },

        init (data) {
            this.myChart = echarts.init(document.getElementById('left-pie'))

            console.log(
                'this.account.oneScoreList.length:' + this.account.oneScoreList.length
            )
            const option = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    data: [{
                        name: '问题员工',
                        textStyle: {
                            padding: [0, 82, 0, 0],
                            fontSize: '14'

                        }
                    }, {
                        name: '差距员工/基本胜任/待发展者',
                        textStyle: {
                            fontSize: '14'
                        }
                    },
                    {
                        name: '熟练员工/中坚力量',
                        textStyle: {
                            padding: [0, 20, 0, 0],
                            fontSize: '14'
                        }
                    },
                    {
                        name: '潜力之星/绩效之星/超级明星',
                        textStyle: {
                            fontSize: '14'
                        }
                    }
                    ],
                    bottom: '0',
                    left: 'center',
                    icon: 'circle',
                    // itemWidth: 80,
                    itemGap: 5,
                    align: 'left',
                    width: '500px'
                },
                series: [
                    {
                        top: '5%',
                        // height:"auto",
                        name: '人才盘点',
                        color: ['#DF5A68', '#E6A23C', '#39A36B', '#3595E1'],
                        type: 'pie',
                        radius: ['40%', '70%'],
                        center: ['50%', '35%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 4
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: false,
                                fontSize: '15',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: this.account.oneScoreList.length, name: '问题员工' },
                            {
                                value:
                  this.account.threeScoreList.length +
                  this.account.twoScoreList.length +
                  this.account.fourScoreList.length,
                                name: '差距员工/基本胜任/待发展者'
                            },
                            {
                                value:
                  this.account.sixScoreList.length +
                  this.account.fiveScoreList.length,
                                name: '熟练员工/中坚力量'
                            },
                            {
                                value:
                  this.account.sevenScoreList.length +
                  this.account.eightScoreList.length +
                  this.account.nineScoreList.length,
                                name: '潜力之星/绩效之星/超级明星'
                            }
                        ]
                    }
                ]
            }
            this.myChart.setOption(option)
        },

        handleDeptChange (val) {
            this.bussinessDepts = []
            let flag = true
            if (this.bussinessDepts.length > 0) {
                this.bussinessDepts.forEach((item) => {
                    if (item.isAdmin && flag) {
                        item.groupId = val.id
                        item.groupName = val.groupName
                        flag = false
                    } else {
                        item.isAdmin = false
                    }
                })
            } else {
                if (val) {
                    this.bussinessDepts.push({
                        groupId: val.id,
                        groupName: val.groupName,
                        isAdmin: true,
                        isPrimary: true,
                        isOwn: false
                    })
                }
            }
            this.getHomeData()
        },
        changeType (val) {
            console.log('ssss:' + val)
            this.showDialog(val)
        },
        clear () {
            console.log('ssfssfsd')
        },
        showDialog (val) {
            this.typeValue = val
            this.employeeList = []
            if (val == 1) {
                this.employeeList = this.account.oneScoreList
            }
            if (val == 2) {
                this.employeeList = this.account.twoScoreList
            }
            if (val == 3) {
                this.employeeList = this.account.threeScoreList
            }
            if (val == 4) {
                this.employeeList = this.account.fourScoreList
            }
            if (val == 5) {
                this.employeeList = this.account.fiveScoreList
            }
            if (val == 6) {
                this.employeeList = this.account.sixScoreList
            }
            if (val == 7) {
                this.employeeList = this.account.sevenScoreList
            }
            if (val == 8) {
                this.employeeList = this.account.eightScoreList
            }
            if (val == 9) {
                this.employeeList = this.account.nineScoreList
            }
            this.visible = true
        },

        // 拼接数据
        async assembleScore (data) {
            let oneScoreList = []
            let twoScoreList = []
            let threeScoreList = []
            let fourScoreList = []
            let fiveScoreList = []
            let sixScoreList = []
            let sevenScoreList = []
            let eightScoreList = []
            let nineScoreList = []
            for (var i = 0; i < data.length; i++) {
                if (data[i].indicatorValue == 1) {
                    oneScoreList.push(data[i])
                }
                if (data[i].indicatorValue == 2) {
                    twoScoreList.push(data[i])
                }
                if (data[i].indicatorValue == 3) {
                    threeScoreList.push(data[i])
                }
                if (data[i].indicatorValue == 4) {
                    fourScoreList.push(data[i])
                }
                if (data[i].indicatorValue == 5) {
                    fiveScoreList.push(data[i])
                }
                if (data[i].indicatorValue == 6) {
                    sixScoreList.push(data[i])
                }
                if (data[i].indicatorValue == 7) {
                    sevenScoreList.push(data[i])
                }
                if (data[i].indicatorValue == 8) {
                    eightScoreList.push(data[i])
                }
                if (data[i].indicatorValue == 9) {
                    nineScoreList.push(data[i])
                }
            }
            this.account.oneScoreList = oneScoreList
            this.account.twoScoreList = twoScoreList
            this.account.threeScoreList = threeScoreList
            this.account.fourScoreList = fourScoreList
            this.account.fiveScoreList = fiveScoreList
            this.account.sixScoreList = sixScoreList
            this.account.sevenScoreList = sevenScoreList
            this.account.eightScoreList = eightScoreList
            this.account.nineScoreList = nineScoreList
        }
    }
}
</script>
<style lang="less" scoped>
/* stylelint-disable */

section {
  margin-top: 0.16rem;
  flex: 1;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: space-around;
  flex-direction: row;
  .title {
    height: 0.24rem;
    font-family: PingFangSC-Regular;
    font-size: 0.16rem;
    color: #a7b6c3;
    line-height: 0.24rem;
    font-weight: 400;
  }
  .gmv {
    height: 0.96rem;
    color: #54636f;
    margin-bottom: 0.36rem;
    .unit {
      font-size: 0.26rem;
      // font-size: 0.4rem;
    }
    .price {
      height: 0.7rem;
      font-family: SanFranciscoDisplay-Medium;
      // font-size: 0.5rem;
      font-size: 0.44rem;
      line-height: 0.7rem;
      font-weight: 500;
    }
  }
  .other {
    display: flex;
    // justify-content: space-between;
    color: #54636f;
    flex: 1;
    .unit {
      // font-size: 0.25rem;
      font-size: 0.2rem;
    }
    .price {
      height: 0.44rem;
      font-family: SanFranciscoDisplay-Medium;
      font-size: 0.24rem;
      line-height: 0.44rem;
      font-weight: 500;
    }
    .price.green {
      font-size: 0.24rem;
      color: #39a36b;
    }
    .price.red {
      font-size: 0.24rem;
      color: #df5a68;
    }

    div:nth-child(1) {
      width: 50%;
    }
    div:nth-child(2) {
      padding-left: 25px;
      flex: 1;
    }
  }
  .main-left {
    flex: 1;
    margin-right: 120px;
    #left-pie {
      width: 100%;
      height: 100%;
    }
  }
  .main-right {
    display: flex;
    align-items: center;
    margin-right: 100px;
    .grid-data {
      height: 254px;
      position: relative;
      display: grid;
      padding: 4px;
      // width: 470px;
      // height: 260px;
      border-left: 2px solid #c0c4cc;
      border-bottom: 2px solid #c0c4cc;
      grid-template-columns: 152px 152px 152px;
      grid-template-rows: 82px 82px 82px;
      grid-row-gap: 4px;
      grid-column-gap: 4px;
    }
    .grid-data-item {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #3595e1;
      line-height: 24px;
      font-weight: 500;
      border: 2px solid #3595e1;
      border-radius: 12px;
      box-sizing: border-box;
    }
    .grid-data-item-low {
      color: #39a36b;
      border: 2px solid #39a36b;
    }
    .grid-data-item-medium {
      color: #E6A23C;
      border: 2px solid #E6A23C;
    }
    .grid-data-item-high {
      color: #df5a68;
      border: 2px solid #df5a68;
    }
    .position-top,
    .position-bottom {
      position: absolute;
    }
    .position-top,
    .position-bottom {
      font-size: 14px;
      color: #a7b6c3;
      font-weight: 400;
    }
    .position-top {
      left: -110px;
      margin-top: -7px;
      text-align: right;
      width: 100px;
    }
    .top-text,
    .bottom-text {
      color: #606266;
      font-weight: 500;
    }
    .top-text {
      bottom: 100%;
      margin-bottom: -7px;
    }
    .high-rank {
      top: 83%;
    }
    .medium-rank {
      top: 50%;
    }
    .low-rank {
      top: 16%;
    }
    .position-bottom {
      bottom: -30px;
      width: auto;
      margin-left: -7px;
    }
    .bottom-text {
      left: 100%;
      width: 100px;
      margin-left: -20px;
    }
    .low-level {
      left: 16%;
    }
    .medium-level {
      left: 50%;
    }
    .high-level {
      left: 83%;
    }
    .custom-arrow-top,
    .custom-arrow-bottom {
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
    .custom-arrow-top {
      background-image: url(../../../assets/images/grid_arrow_top.png);
      width: 4px;
      height: 10px;
      left: -4px;
      top: -10px;
      margin-top: 0;
    }
    .custom-arrow-bottom {
      width: 10px;
      height: 4px;
      right: -10px;
      bottom: -2px;
      background-image: url(../../../assets/images/grid_arrow_right.png);
    }
  }
}
</style>
